// extracted by mini-css-extract-plugin
export var carouselContainer = "F_vK d_w d_H d_bf d_Z";
export var carouselContainerCards = "F_vL F_vK d_w d_H d_bf d_Z";
export var carouselContainerSides = "F_vM d_w d_H d_Z";
export var prevCarouselItem = "F_vN d_w d_H d_0 d_k";
export var prevCarouselItemL = "F_vP F_vN d_w d_H d_0 d_k";
export var moveInFromLeft = "F_vQ";
export var prevCarouselItemR = "F_vR F_vN d_w d_H d_0 d_k";
export var moveInFromRight = "F_vS";
export var selectedCarouselItem = "F_vT d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "F_vV F_vT d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "F_vW F_vT d_w d_H d_Z d_bf";
export var nextCarouselItem = "F_vX d_w d_H d_0 d_k";
export var nextCarouselItemL = "F_vY F_vX d_w d_H d_0 d_k";
export var nextCarouselItemR = "F_vZ F_vX d_w d_H d_0 d_k";
export var arrowContainer = "F_v0 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "F_v1 F_v0 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "F_v2 F_v1 F_v0 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "F_v3 F_v0 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "F_v4";
export var nextArrowContainerHidden = "F_v5 F_v3 F_v0 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "F_kG d_0";
export var prevArrow = "F_v6 F_kG d_0";
export var nextArrow = "F_v7 F_kG d_0";
export var carouselIndicatorContainer = "F_v8 d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "F_v9 d_w d_bz d_bF";
export var carouselText = "F_wb d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "F_wc F_wb d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "F_wd d_b7";
export var carouselIndicator = "F_wf F_wd d_b7";
export var carouselIndicatorSelected = "F_wg F_wd d_b7";
export var arrowsContainerTopRight = "F_wh d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "F_wj d_0 d_bl d_bC";
export var arrowsContainerSides = "F_wk d_0 d_bl d_bC";
export var smallArrowsBase = "F_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "F_wm F_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "F_wn F_wm F_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "F_wp F_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "F_wq F_wp F_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "F_p9";
export var iconSmall = "F_wr";
export var multipleWrapper = "F_ws d_bC d_bF d_bf";
export var multipleImage = "F_wt d_bC";
export var sidesPrevContainer = "F_wv F_wm F_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "F_ww F_wm F_wl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";