// extracted by mini-css-extract-plugin
export var lbContainerOuter = "D_vv";
export var lbContainerInner = "D_vw";
export var movingForwards = "D_vx";
export var movingForwardsOther = "D_vy";
export var movingBackwards = "D_vz";
export var movingBackwardsOther = "D_vB";
export var lbImage = "D_vC";
export var lbOtherImage = "D_vD";
export var prevButton = "D_vF";
export var nextButton = "D_vG";
export var closing = "D_vH";
export var appear = "D_vJ";